import * as React from 'react';
import cx from 'classnames';
import { createSvgIcon } from '../utils/createSvgIcon';
import { iconClassNames } from '../utils/iconClassNames';

export const SyncIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg role="presentation" focusable="false" viewBox="2 2 16 16" className={classes.svg}>
      <g className={cx(iconClassNames.outline, classes.outlinePart)}>
        <path d="M8.58575 3.63607C8.39049 3.44081 8.39049 3.12422 8.58575 2.92896L10.7071 0.807641C10.9023 0.612378 11.2189 0.612378 11.4142 0.80764C11.6094 1.0029 11.6094 1.31949 11.4142 1.51475L10.4173 2.5116C12.1948 2.61014 13.9451 3.33857 15.3032 4.69674C18.2322 7.62567 18.2322 12.3744 15.3032 15.3033C15.0463 15.5602 14.7752 15.7947 14.4924 16.0068C14.2714 16.1725 13.958 16.1276 13.7924 15.9067C13.6267 15.6858 13.6716 15.3724 13.8925 15.2067C14.1376 15.023 14.3728 14.8195 14.5961 14.5962C17.1345 12.0578 17.1345 7.94225 14.5961 5.40384C13.31 4.1177 11.6199 3.48327 9.93357 3.50037C9.90845 3.50063 9.88375 3.49903 9.85959 3.4957L11.4142 5.05028C11.6094 5.24554 11.6094 5.56213 11.4142 5.75739C11.2189 5.95265 10.9023 5.95265 10.7071 5.75739L8.58575 3.63607Z" />
        <path d="M11.4142 16.364C11.6094 16.5593 11.6094 16.8758 11.4142 17.0711L9.29286 19.1924C9.09759 19.3877 8.78101 19.3877 8.58575 19.1924C8.39049 18.9972 8.39049 18.6806 8.58575 18.4853L9.5826 17.4885C7.80516 17.3899 6.05486 16.6615 4.6967 15.3033C1.76777 12.3744 1.76777 7.62566 4.6967 4.69672C4.95358 4.43984 5.22468 4.20532 5.50756 3.99324C5.7285 3.8276 6.04189 3.87242 6.20754 4.09337C6.37318 4.31431 6.32836 4.6277 6.10741 4.79335C5.86233 4.97709 5.62708 5.18056 5.40381 5.40383C2.8654 7.94224 2.8654 12.0578 5.40381 14.5962C6.68995 15.8824 8.37999 16.5168 10.0664 16.4997C10.0915 16.4994 10.1162 16.501 10.1403 16.5044L8.58575 14.9498C8.39049 14.7545 8.39049 14.4379 8.58575 14.2427C8.78101 14.0474 9.09759 14.0474 9.29286 14.2427L11.4142 16.364Z" />
      </g>
      <g className={cx(iconClassNames.filled, classes.filledPart)}>
        <path d="M10.115 3.75102C11.677 3.77953 13.2281 4.38929 14.4194 5.58055C16.8602 8.02133 16.8602 11.9786 14.4194 14.4194C14.2045 14.6343 13.9782 14.83 13.7426 15.0066C13.4112 15.2551 13.3439 15.7252 13.5924 16.0566C13.8409 16.388 14.311 16.4552 14.6424 16.2068C14.9347 15.9876 15.2148 15.7453 15.48 15.48C18.5066 12.4535 18.5066 7.54645 15.48 4.51989C14.1328 3.17266 12.4118 2.42506 10.6516 2.27728L11.2374 1.69146C11.5303 1.39857 11.5303 0.923696 11.2374 0.630803C10.9445 0.337909 10.4696 0.33791 10.1767 0.630803L8.05541 2.75212C7.76252 3.04502 7.76252 3.51989 8.05541 3.81278L10.1767 5.9341C10.4696 6.227 10.9445 6.227 11.2374 5.9341C11.5303 5.64121 11.5303 5.16634 11.2374 4.87344L10.115 3.75102Z" />
        <path d="M9.88499 16.2489C8.32297 16.2204 6.77185 15.6106 5.58058 14.4193C3.13981 11.9786 3.13981 8.02129 5.58058 5.58051C5.79546 5.36563 6.02174 5.16994 6.25738 4.99328C6.58879 4.74481 6.65603 4.27472 6.40756 3.9433C6.1591 3.61189 5.68901 3.54465 5.35759 3.79312C5.06527 4.01228 4.7852 4.25457 4.51992 4.51985C1.49336 7.54641 1.49336 12.4534 4.51992 15.48C5.86715 16.8272 7.58813 17.5748 9.34838 17.7226L8.76256 18.3084C8.46967 18.6013 8.46967 19.0762 8.76256 19.3691C9.05546 19.662 9.53033 19.662 9.82322 19.3691L11.9445 17.2478C12.2374 16.9549 12.2374 16.48 11.9445 16.1871L9.82322 14.0658C9.53033 13.7729 9.05546 13.7729 8.76256 14.0658C8.46967 14.3587 8.46967 14.8336 8.76256 15.1264L9.88499 16.2489Z" />
      </g>
    </svg>
  ),
  displayName: 'SyncIcon',
});
