import * as React from 'react';
import cx from 'classnames';
import { createSvgIcon } from '../utils/createSvgIcon';
import { iconClassNames } from '../utils/iconClassNames';

export const SettingsIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg
      style={{ overflow: 'visible' }}
      role="presentation"
      focusable="false"
      viewBox="2 2 16 16"
      className={classes.svg}
    >
      <path
        className={cx(iconClassNames.outline, classes.outlinePart)}
        d="M1.911 7.383a8.491 8.491 0 011.78-3.08.5.5 0 01.54-.135l1.918.686a1 1 0 001.32-.762l.366-2.006a.5.5 0 01.388-.4 8.532 8.532 0 013.554 0 .5.5 0 01.388.4l.366 2.006a1 1 0 001.32.762l1.919-.686a.5.5 0 01.54.136 8.491 8.491 0 011.78 3.079.5.5 0 01-.153.535l-1.555 1.32a1 1 0 000 1.524l1.555 1.32a.5.5 0 01.152.535 8.491 8.491 0 01-1.78 3.08.5.5 0 01-.54.135l-1.918-.686a1 1 0 00-1.32.762l-.366 2.007a.5.5 0 01-.388.399 8.528 8.528 0 01-3.554 0 .5.5 0 01-.388-.4l-.366-2.006a1 1 0 00-1.32-.762l-1.918.686a.5.5 0 01-.54-.136 8.49 8.49 0 01-1.78-3.079.5.5 0 01.152-.535l1.555-1.32a1 1 0 000-1.524l-1.555-1.32a.5.5 0 01-.152-.535zm1.06-.006l1.294 1.098a2 2 0 010 3.05l-1.293 1.098c.292.782.713 1.51 1.244 2.152l1.596-.57a2 2 0 012.64 1.525l.305 1.668a7.556 7.556 0 002.486 0l.304-1.67a1.998 1.998 0 012.641-1.524l1.596.571a7.492 7.492 0 001.245-2.152l-1.294-1.098a1.998 1.998 0 010-3.05l1.294-1.098a7.491 7.491 0 00-1.245-2.152l-1.596.57a2 2 0 01-2.64-1.524l-.305-1.669a7.555 7.555 0 00-2.486 0l-.304 1.669a2 2 0 01-2.64 1.525l-1.597-.571a7.491 7.491 0 00-1.244 2.152zM7.502 10a2.5 2.5 0 115 0 2.5 2.5 0 01-5 0zm1 0a1.5 1.5 0 103 0 1.5 1.5 0 00-3 0z"
      />
      <path
        className={cx(iconClassNames.filled, classes.filledPart)}
        d="M1.911 7.383a8.491 8.491 0 011.78-3.08.5.5 0 01.54-.135l1.918.686a1 1 0 001.32-.762l.366-2.006a.5.5 0 01.388-.4 8.532 8.532 0 013.554 0 .5.5 0 01.388.4l.366 2.006a1 1 0 001.32.762l1.919-.686a.5.5 0 01.54.136 8.491 8.491 0 011.78 3.079.5.5 0 01-.153.535l-1.555 1.32a1 1 0 000 1.524l1.555 1.32a.5.5 0 01.152.535 8.491 8.491 0 01-1.78 3.08.5.5 0 01-.54.135l-1.918-.686a1 1 0 00-1.32.762l-.366 2.007a.5.5 0 01-.388.399 8.528 8.528 0 01-3.554 0 .5.5 0 01-.388-.4l-.366-2.006a1 1 0 00-1.32-.762l-1.918.686a.5.5 0 01-.54-.136 8.49 8.49 0 01-1.78-3.079.5.5 0 01.152-.535l1.555-1.32a1 1 0 000-1.524l-1.555-1.32a.5.5 0 01-.152-.535zM8.001 10a2 2 0 104 0 2 2 0 00-4 0z"
      />
    </svg>
  ),
  displayName: 'SettingsIcon',
});
